import React, { useState } from 'react'
import BinIcon from '../assets/images/bin-icon.svg'
import EditIcon from '../assets/images/edit-pen.svg'
import DeleteModal from './DeleteModal';
import { deleteFaq } from '../service/AdminApi';
import { useCallback } from "react";
import { GlobalContext, showToast } from "../globalContext";
import EditFaqModal from './EditFaqModal';
const FaqQuestionBoxes = ({ quest, ans, id, getData }) => {

    const { dispatch: globalDispatch } = React.useContext(GlobalContext);


    const [showAns, setShowAns] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const onCloseDeleteModal = useCallback(() => {
        setDeleteModal(false);
    }, [deleteModal]);
    const onCloseEditModal = useCallback(() => {
        setShowEditModal(false);
    }, [showEditModal]);



    const onDelete = () => {

        deleteFaq(id, (success, resp) => {
            if (success) {
                getData()
                showToast(globalDispatch, "Deleted");
            } else {
                alert('something went wrong!')
            }

        });
    }

    return (
        <>
            {deleteModal &&
                <DeleteModal closeModal={onCloseDeleteModal} deleteCard={onDelete} />
            }
            {showEditModal &&

                <EditFaqModal getData={getData} quest={quest} ans={ans} id={id} closeModal={onCloseEditModal} />
            }
            <div className="block w-full md:p-[20px] p-[10px] bg-[white] rounded-[10px] ">
                <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between gap-3  cursor-pointer"
                        onClick={() => {
                            setShowAns(!showAns)
                        }}
                    >
                        <h3 className='md:text-[20px] text-[16px] text-[#1C1C1C]'>{quest}</h3>
                        <div className="flex items-center gap-3 ">
                        <button type='button' className={`w-fit ${showAns ? ' rotate-180' : ''}`}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1020_23384)">
                                    <rect y="0.5" width="20" height="20" rx="10" fill="#F2F2F2" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8828 13.8843C10.6484 14.1184 10.3307 14.2499 9.99943 14.2499C9.66818 14.2499 9.35047 14.1184 9.1161 13.8843L4.4011 9.17097C4.16671 8.93647 4.03508 8.61847 4.03516 8.28692C4.03523 7.95537 4.16702 7.63743 4.40151 7.40305C4.63601 7.16866 4.95401 7.03703 5.28556 7.03711C5.61711 7.03719 5.93504 7.16897 6.16943 7.40347L9.99943 11.2335L13.8294 7.40347C14.0651 7.17565 14.3808 7.04951 14.7085 7.0522C15.0363 7.0549 15.3498 7.18621 15.5817 7.41786C15.8136 7.64951 15.9452 7.96297 15.9482 8.29071C15.9512 8.61846 15.8254 8.93427 15.5978 9.17013L10.8836 13.8851L10.8828 13.8843Z" fill="#F38030" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1020_23384">
                                        <rect y="0.5" width="20" height="20" rx="10" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                     
                                <button type='button'
                                    onClick={() => {
                                        setDeleteModal(true)
                                    }}
                                >
                                    <img className=' min-w-[20px]' src={BinIcon} alt='' />
                                </button>

                                <button type='button' onClick={() => { setShowEditModal(true) }}>
                                    <img className=' min-w-[20px]' src={EditIcon} />
                                </button>
                            </div>
                    </div>

                    {showAns &&
                        <div className="bg-[#F3F3F3] rounded-[10px] p-[15px] fader">
                            <p className='md:text-[16px] text-[12px] text-[#3C3A3F]'>
                                {ans}
                            </p>


                          
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default FaqQuestionBoxes

import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { editFaq } from '../service/AdminApi';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GlobalContext, showToast } from "../globalContext";

const EditFaqModal = ({ getData, quest, ans, id, closeModal }) => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);



    const schema = yup
        .object({
            question: yup.string().required(),
            answer: yup.string().required(),
        })


    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        editFaq(data, (success, resp) => {
            if (success) {
                showToast(globalDispatch, "Updated");
                getData()
                closeModal()
            } else {
                alert('something went wrong!')
            }

        });
    };

    useEffect(function () {
        setValue("answer", ans);
        setValue("id", id);
        setValue("question", quest);
      }, []);



    return (
        <>



            <div className="modal-holder">
                <div className="modal-outer" onClick={() => { closeModal() }}></div>
                <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
                    <div className="flex items-center justify-between">
                        <h4 className='text-[24px] text-[#2B2B2B] font-[400]'>Edit FAQ</h4>
                    </div>


                    <form className='mt-8' onSubmit={handleSubmit(onSubmit)} >
                        <div className="flex flex-col gap-4">
                            <div className="popup-field-box">
                                <input {...register("id")} type="hidden" />
                                <label>Edit Question</label>
                                <input
                                    {...register("question")}
                                    type='text' />
                                <p className="text-red-500 text-xs italic">
                                    {errors.question?.message}
                                </p>
                            </div>
                            <div className="popup-field-box">
                                <label>Edit Answer</label>
                                <textarea
                                    {...register("answer")}
                                    type='text' />
                                <p className="text-red-500 text-xs italic">
                                    {errors.answer?.message}
                                </p>
                            </div>



                            <div className="flex gap-3">
                                <button type='button' onClick={() => { closeModal() }} className='py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]'>Cancel</button>
                                <button type="submit" className='bg-[#F38030] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
                                    Edit
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </>
    )
}

export default EditFaqModal

import React from "react";
import { useState } from "react";
import RevervationListCard from "./RevervationListCard";
import Pagination from "./Pagination";
import { getAdminReservations } from "../service/AdminApi";

const AllReservations = ({ reservCount }) => {
  const [checkIn, setCheckIn] = useState(false);
  const [checkOut, setCheckOut] = useState(false);
  const [filter, setFilter] = useState('')
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('')
  const [date, setDate] = useState('')

  const [reservationList, setReservationList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getData = async (page, search, date, filter) => {
    reservCount()
    setIsLoading(true); // Set loading state before API call

    try {
      // let searchParams = "";
      // if (page) {
      //   searchParams += `&page=${page}`;
      // }
      // if (search) {
      //   searchParams += `&q=${search}`;
      // }
      // if (date) {
      //   searchParams += `&date=${date}`
      // }
      // if (filter) {
      //   searchParams += `&filter_by=${filter}`
      // }


      const resList = await getAdminReservations(page, search, date, filter);

      // Handle the "No data found" case
      if (resList?.error) {
        setReservationList([]); // Clear the list
        setTotalPages(0);
        setCurrentPage(0);
        // Optionally display a "No data found" message 
      } else {
        // Proceed normally if the response has data
        const tPages =resList.last_page;
        setTotalPages(tPages);
        setCurrentPage(resList.current_page);
        setReservationList(resList?.data);
      }

    } catch (error) {
      console.error("Error fetching reservations:", error);
      setReservationList([]);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getData(1, search, date, filter);
  }, []);

  return (
    <>
      <div className="w-full p-[20px] rounded-[20px] bg-white mt-6">
        <div className="flex justify-between items-center flex-wrap gap-3 ">
          <div className="flex items-center md:gap-8 gap-4 flex-wrap row-gap-3 ">
            <h4 className="text-[20px] text-[#1C1C1C] font-[400] uppercase">
              ALL Reservations
            </h4>
            <div className="flex items-center gap-8 filter-check-btns">
              <button
                onClick={() => {
                  setCheckIn(!checkIn);
                  setCheckOut(false);
                  setFilter('check_in')
                  getData(1, search, date, 'check_in');
                }}
              >
                <span className={`${checkIn ? "checked" : ""}`}></span>
                Check in
              </button>
              <button
                onClick={() => {
                  setCheckOut(!checkOut);
                  setCheckIn(false);
                  setFilter('check_out')
                  getData(1, search, date, 'check_out');
                }}
              >
                <span className={`${checkOut ? "checked" : ""}`}></span>
                Check out
              </button>
            </div>
          </div>
          <div className="flex items-center gap-6 relative">
            <input type="date" onChange={(e) => {
              setDate(e.target.value)
              getData(1, search, e.target.value, filter);

            }} className=" absolute left-[0px] top-0 w-[75px] opacity-0 cursor-pointer h-full z-10" />
            <button
              className="flex cursor-pointer items-center gap-[7px] py-2 px-2 rounded-[10px] bg-[#F2F2F2] text-[#7A7A7A] text-[12px] font-[800]"
              type="button"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.875 12.375H3.125V5.5H11.875M10 1.125V2.375H5V1.125H3.75V2.375H3.125C2.43125 2.375 1.875 2.93125 1.875 3.625V12.375C1.875 12.7065 2.0067 13.0245 2.24112 13.2589C2.47554 13.4933 2.79348 13.625 3.125 13.625H11.875C12.2065 13.625 12.5245 13.4933 12.7589 13.2589C12.9933 13.0245 13.125 12.7065 13.125 12.375V3.625C13.125 3.29348 12.9933 2.97554 12.7589 2.74112C12.5245 2.5067 12.2065 2.375 11.875 2.375H11.25V1.125M10.625 8H7.5V11.125H10.625V8Z"
                  fill="#F38030"
                />
              </svg>
              {date ? date : 'Today'}
            </button>
            <div className="block w-[1px] h-[36px] bg-[#E3E3E3]"></div>
            <div className="bg-[#FAFAFA] border rounded-[10px] py-1 px-2 border-[#F0F0F0] flex items-center gap-2">
              <input
                placeholder="Search"
                type="text"
                onChange={(e) => {
                  setSearch(e.target.value)
                  setTimeout(() => {
                    getData(1, e.target.value, date, filter);
                  }, 300);
                }}
                className="shadow-none bg-[transparent] text-[#7A7A7A] text-[12px] font-[400] p-0 border-0 w-[100px]"
              />
              <button type="button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.90906 2C5.93814 2 4.98903 2.28791 4.18174 2.82733C3.37444 3.36674 2.74524 4.13343 2.37368 5.03045C2.00213 5.92746 1.90491 6.91451 2.09433 7.86677C2.28375 8.81904 2.75129 9.69375 3.43783 10.3803C4.12438 11.0668 4.99909 11.5344 5.95135 11.7238C6.90362 11.9132 7.89067 11.816 8.78768 11.4444C9.6847 11.0729 10.4514 10.4437 10.9908 9.63639C11.5302 8.8291 11.8181 7.87998 11.8181 6.90906C11.818 5.60712 11.3008 4.35853 10.3802 3.43792C9.45959 2.51731 8.211 2.00008 6.90906 2Z"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M10.5703 10.5723L13.9987 14.0007"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 mt-4 max-[100%] w-[100%]">
          {isLoading ? (
            <div className="text-center w-fit my-4 mx-auto">
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )
            : <>{
              reservationList.length > 0 ? (
                reservationList.map((item) => (
                  <>
                    <RevervationListCard
                      getData={getData}
                      id={item.id}
                      user_id={item.user_id}
                      user_name={item.user_name}
                      email={item.email}
                      phone_number={item.phone_number}
                      car_make={item.car_make}
                      car_model={item.car_model}
                      car_color={item.car_color}
                      airport_id={item.airport_id}
                      airport_name={item.airport_name}
                      coupon_id={item.coupon_id}
                      coupon_name={item.coupon_name}
                      coupon_code={item.coupon_code}
                      coupon_percentage={item.coupon_percentage}
                      package_id={item.package_id}
                      package_name={item.package_name}
                      package_nick_name={item.package_nick_name}
                      package_amount={Number(item.package_amount).toFixed(2)}
                      check_in={item.check_in}
                      check_out={item.check_out}
                      sub_total={Number(item.sub_total).toFixed(2)}
                      discount={Number(item.discount).toFixed(2)}
                      total={Number(item.total).toFixed(2)}
                      due_now={Number(item.due_now).toFixed(2)}
                      payment_method_name={item.payment_method_name}
                      payment_transaction_id={item.payment_transaction_id}
                      payment_card_name={item.payment_card_name}
                      payment_card_number={item.payment_card_number}
                      payment_card_expiry={item.payment_card_expiry}
                      payment_card_cvc={item.payment_card_cvc}
                      status={item.status}
                      cancel_reason={item.cancel_reason}
                      created_at={item.created_at}
                      updated_at={item.updated_at}
                      date={date}
                      search={search}
                      filter={filter}
                    />
                  </>
                ))
              ) : (
                <div className="text-center p-4">No reservations found</div>
              )
            }</>
          }

        </div>

        <div className="flex justify-between w-fit mx-auto mt-6">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPrev={() => {
              if (currentPage > 1) {
                getData(currentPage - 1, search, date, filter);
                setCurrentPage(currentPage - 1);
              }
            }}
            onNext={() => {
              if (currentPage < totalPages) {
                getData(currentPage + 1, search, date, filter);
                setCurrentPage(currentPage + 1);
              }
            }}
            onPage={(page) => {

              getData(page, search, date, filter);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AllReservations;

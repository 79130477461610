import React from "react";
import { debounce } from 'lodash'; // Import debounce 
const Pagination = ({ totalPages, currentPage, onPage, onNext, onPrev }) => {


  const disablePrevButton = currentPage === 1;
  const disableNextButton = currentPage === totalPages;
  const debouncedNext = debounce(() => {
    if (!disableNextButton) onNext && onNext();
  }, 300); // 300ms delay
  
  const debouncedPrev = debounce(() => {
    if (!disablePrevButton) onPrev && onPrev();
  }, 300); // 300ms delay
  const getPaginationList = () => {
    
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 5);

    return range(startPage, endPage );
  };

  const range = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx);
  }

  return (
    <div className="flex items-center gap-2">
      <span
         onClick={debouncedPrev}  // Use debouncedPrev
        className=" cursor-pointer  p-1 min-w-[32px] min-h-[32px] rounded-[8px] bg-[#F2F2F2] text-[#7A7A7A] text-[13px] font-[700] flex items-center justify-center line-height-100"
      >
        Prev
      </span>
      {getPaginationList().map((page) => { // Change is here!
        const isActive = currentPage === page; // Adjust equality check 
        return (
          <span
            onClick={() => {
              onPage && onPage(page); // Use the generated page number
            }}
            className={`cursor-pointer p-1 min-w-[32px] min-h-[32px] rounded-[8px] ${isActive?'bg-[#F38030]':'bg-[#CCCCCC]'} text-[white] text-[13px] font-[700] flex items-center justify-center line-height-100`}
          >
            {page}
          </span>
        );
      })}
    
      <span
         onClick={debouncedNext} // Use debouncedNext
        className="cursor-pointer p-1 min-w-[32px] min-h-[32px] rounded-[8px] bg-[#F2F2F2] text-[#0E0F0F] text-[13px] font-[700] flex items-center justify-center line-height-100"
      >
        Next
      </span>
    </div>
  );
};

export default Pagination;

import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GlobalContext, showToast } from "../globalContext";
import EditIcon from '../assets/images/day-edit.svg'
import { GetHours } from '../service/AdminApi';
import { editHours } from '../service/AdminApi';


const OperatingHoursBox = () => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [editMonday, setEditMonday] = useState(false);
    const [editTuesday, setEditTuesday] = useState(false);
    const [editWednesday, setEditWednesday] = useState(false);
    const [editThursday, setEditThursday] = useState(false);
    const [editFriday, setEditFriday] = useState(false);
    const [editSaturday, setEditSaturday] = useState(false);
    const [editSunday, setEditSunday] = useState(false)
    const [editPhone, setEditPhone] = useState(false)
    const [editEmail, setEditEmail] = useState(false)
    const [editAddress, setEditAddress] = useState(false)
    const [hours, setHours] = useState('');


    const schema = yup
        .object({
            id: yup.string().required(),
            monday_start_time: yup.string().required(),
            monday_end_time: yup.string().required(),
            tuesday_start_time: yup.string().required(),
            tuesday_end_time: yup.string().required(),
            wednesday_start_time: yup.string().required(),
            wednesday_end_time: yup.string().required(),
            thursday_start_time: yup.string().required(),
            thursday_end_time: yup.string().required(),
            friday_start_time: yup.string().required(),
            friday_end_time: yup.string().required(),
            saturday_start_time: yup.string().required(),
            saturday_end_time: yup.string().required(),
            sunday_start_time: yup.string().required(),
            sunday_end_time: yup.string().required(),
            email: yup.string().required(),
            phone_number: yup.string().required(),
            location: yup.string().required(),
        })
        .required();

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });


    const getData = async () => {
        const AllHours = await GetHours();

        setHours(AllHours);


    };



    const onSubmit = async (data) => {
        editHours(data, (success, resp) => {
            if (success) {
                getData()
                showToast(globalDispatch, "Hours Updated");
            } else {
                alert('something went wrong!')
            }

        });
    };

    useEffect(function () {
        getData()

    }, []);

    useEffect(function () {

        setValue("id", hours.id);
        setValue("monday_start_time", hours.monday_start_time);
        setValue("monday_end_time", hours.monday_end_time);
        setValue("tuesday_start_time", hours.tuesday_start_time);
        setValue("tuesday_end_time", hours.tuesday_end_time);
        setValue("wednesday_start_time", hours.wednesday_start_time);
        setValue("wednesday_end_time", hours.wednesday_end_time);
        setValue("thursday_start_time", hours.thursday_start_time);
        setValue("thursday_end_time", hours.thursday_end_time);
        setValue("friday_start_time", hours.friday_start_time);
        setValue("friday_end_time", hours.friday_end_time);
        setValue("saturday_start_time", hours.saturday_start_time);
        setValue("saturday_end_time", hours.saturday_end_time);
        setValue("sunday_start_time", hours.sunday_start_time);
        setValue("sunday_end_time", hours.sunday_end_time);

        setValue("email", hours.email);
        setValue("phone_number", hours.phone_number);
        setValue("location", hours.location);

    }, [hours]);




    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}  className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-6">
                <div className="w-full p-[20px] rounded-[20px] bg-white">
                    <div className="flex justify-between items-center">
                        <h4 className='md:text-[20px] text-[16px] text-[#1C1C1C] font-[400] uppercase'>Operating hours</h4>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_991_21614)">
                                <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="#F2F2F2" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8828 7.1157C10.6484 6.88162 10.3307 6.75013 9.99943 6.75013C9.66818 6.75013 9.35047 6.88162 9.1161 7.1157L4.4011 11.829C4.16671 12.0635 4.03508 12.3815 4.03516 12.7131C4.03523 13.0446 4.16702 13.3626 4.40151 13.597C4.63601 13.8313 4.95401 13.963 5.28556 13.9629C5.61711 13.9628 5.93504 13.831 6.16943 13.5965L9.99943 9.76653L13.8294 13.5965C14.0651 13.8243 14.3808 13.9505 14.7085 13.9478C15.0363 13.9451 15.3498 13.8138 15.5817 13.5821C15.8136 13.3505 15.9452 13.037 15.9482 12.7093C15.9512 12.3815 15.8254 12.0657 15.5978 11.8299L10.8836 7.11487L10.8828 7.1157Z" fill="#F38030" />
                            </g>
                            <defs>
                                <clipPath id="clip0_991_21614">
                                    <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="w-full bg-[#F3F3F3] rounded-[10px] p-[20px] mt-4">
                        <div className="w-full bg-[#FFFFFF] p-[20px] rounded-[10px] border border-[#C5C5C5]">
                        <input   {...register("id")} type='hidden' />

                            {/* time box */}
                            <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Monday <img src={EditIcon}
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditMonday(!editMonday)
                                    }}
                                    alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.monday_start_time ? 'border-red' : ''}  ${editMonday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'}   `}   {...register("monday_start_time")} type='time' />

                                    <p>-</p>
                                    <input className={`${errors.monday_end_time ? 'border-red' : ''} ${editMonday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'}  `}   {...register("monday_end_time")} type='time' />
                                </div>
                            </div>

                            {/* time box */}
                            <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Tuesday <img
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditTuesday(!editTuesday)
                                    }}
                                    src={EditIcon} alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.tuesday_start_time ? 'border-red' : ''} ${editTuesday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("tuesday_start_time")} type='time' />
                                    <p>-</p>
                                    <input className={`${errors.tuesday_start_time ? 'border-red' : ''} ${editTuesday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("tuesday_end_time")} type='time' />
                                </div>
                            </div>

                            {/* time box */}
                            <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Wednesday <img
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditWednesday(!editWednesday)
                                    }}
                                    src={EditIcon} alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.wednesday_start_time ? 'border-red' : ''} ${editWednesday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("wednesday_start_time")} type='time' />
                                    <p>-</p>
                                    <input className={`${errors.wednesday_end_time ? 'border-red' : ''}  ${editWednesday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'}`}   {...register("wednesday_end_time")} type='time' />
                                </div>
                            </div>

                            {/* time box */}
                            <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Thursday <img
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditThursday(!editThursday)
                                    }}
                                    src={EditIcon} alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.thursday_start_time ? 'border-red' : ''} ${editThursday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("thursday_start_time")} type='time' />
                                    <p>-</p>
                                    <input className={`${errors.thursday_end_time ? 'border-red' : ''} ${editThursday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("thursday_end_time")} type='time' />
                                </div>
                            </div>

                            {/* time box */}
                            <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Friday <img
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditFriday(!editFriday)
                                    }}
                                    src={EditIcon} alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.friday_start_time ? 'border-red' : ''} ${editFriday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("friday_start_time")} type='time' />
                                    <p>-</p>
                                    <input className={`${errors.friday_end_time ? 'border-red' : ''} ${editFriday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("friday_end_time")} type='time' />
                                </div>
                            </div>

                            {/* time box */}
                            <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Saturday <img
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditSaturday(!editSaturday)
                                    }}
                                    src={EditIcon} alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.saturday_start_time ? 'border-red' : ''} ${editSaturday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("saturday_start_time")} type='time' />
                                    <p>-</p>
                                    <input className={`${errors.saturday_end_time ? 'border-red' : ''} ${editSaturday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("saturday_end_time")} type='time' />
                                </div>
                            </div>

                            {/* time box */}
                            <div className="w-full ">
                                <label className='flex w-full gap-2'>Sunday <img
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditSunday(!editSunday)
                                    }}
                                    src={EditIcon} alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.sunday_start_time ? 'border-red' : ''} ${editSunday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("sunday_start_time")} type='time' />
                                    <p>-</p>
                                    <input className={`${errors.sunday_end_time ? 'border-red' : ''} ${editSunday ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'} `}   {...register("sunday_end_time")} type='time' />
                                </div>
                            </div>



                        </div>
                        <button className='bg-[#1C1C1C] mt-2 rounded-[10px] py-3 px-2 text-[#FFFFFF] text-[12px] font-bold ' type='submit'>Save Changes</button>
                    </div>
                </div>

                {/* email */}

                <div className="w-full p-[20px] rounded-[20px] bg-white">
                    <div className="flex justify-between items-center">
                        <h4 className='md:text-[20px] text-[16px] text-[#1C1C1C] font-[400] uppercase'>Business Info</h4>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_991_21614)">
                                <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="#F2F2F2" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8828 7.1157C10.6484 6.88162 10.3307 6.75013 9.99943 6.75013C9.66818 6.75013 9.35047 6.88162 9.1161 7.1157L4.4011 11.829C4.16671 12.0635 4.03508 12.3815 4.03516 12.7131C4.03523 13.0446 4.16702 13.3626 4.40151 13.597C4.63601 13.8313 4.95401 13.963 5.28556 13.9629C5.61711 13.9628 5.93504 13.831 6.16943 13.5965L9.99943 9.76653L13.8294 13.5965C14.0651 13.8243 14.3808 13.9505 14.7085 13.9478C15.0363 13.9451 15.3498 13.8138 15.5817 13.5821C15.8136 13.3505 15.9452 13.037 15.9482 12.7093C15.9512 12.3815 15.8254 12.0657 15.5978 11.8299L10.8836 7.11487L10.8828 7.1157Z" fill="#F38030" />
                            </g>
                            <defs>
                                <clipPath id="clip0_991_21614">
                                    <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="w-full bg-[#F3F3F3] rounded-[10px] p-[20px] mt-4">
                        <div className="w-full bg-[#FFFFFF] p-[20px] rounded-[10px] border border-[#C5C5C5]">

                            {/* time box */}
                            <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Phone number <img src={EditIcon}
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditPhone(!editPhone)
                                    }}
                                    alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.phone_number ? 'border-red' : ''}  ${editPhone ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'}   `}   {...register("phone_number")} type='text' />        
                                </div>
                            </div>
                             {/* time box */}
                             <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                                <label className='flex w-full gap-2'>Email <img src={EditIcon}
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditEmail(!editEmail)
                                    }}
                                    alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.email ? 'border-red' : ''}  ${editEmail ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'}   `}   {...register("email")} type='mail' />        
                                </div>
                            </div>
                             {/* time box */}
                             <div className="w-full">
                                <label className='flex w-full gap-2'>Location <img src={EditIcon}
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setEditAddress(!editAddress)
                                    }}
                                    alt='' /> </label>
                                <div className="flex items-center gap-2 operate-time-field-holder">
                                    <input className={`${errors.location ? 'border-red' : ''}  ${editAddress ? 'opacity-100 pointer-events-all' : 'opacity-5 pointer-events-none'}   `}   {...register("location")} type='text' />        
                                </div>
                            </div>

                         



                        </div>
                        <button className='bg-[#1C1C1C] mt-2 rounded-[10px] py-3 px-2 text-[#FFFFFF] text-[12px] font-bold ' type='submit'>Save Changes</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default OperatingHoursBox

import { BASE_URL, api } from "./api";
import axios from "axios";

export const login = (params, onResponse) => {
  fetch(BASE_URL + "/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      email: params.email,
      password: params.password,
    }),
  })
    .then((response) => {
      if (!response.ok) {

        // alert(response.token)
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
      
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const register = (params, onResponse) => {
  fetch(BASE_URL + "/auth/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({
      first_name: params.first_name,
      last_name: params.last_name,
      user_name: params.user_name,
      email: params.email,
      phone_number: params.phone_number,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
      
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });

};

import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GlobalContext, showToast } from "../globalContext";
import { cancleReserv } from '../service/AdminApi';

const CancleReservation = ({getData, closeModal, user_id, check_in_date, check_out_date,id, date,search,filter  }) => {
    
    
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    



    const schema = yup
        .object({
            reason: yup.string().required(),
            id: yup.number().required(),
            userid:yup.number().required(),
            check_in: yup.string().required(),
            check_out: yup.string().required(),
        })
        .required();

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {

        cancleReserv(data, (success, resp) => {
            if (success) {
            
            //   getData(1)
              getData(1, search,date,filter);
              closeModal()    
              showToast(globalDispatch, "Reservation canclled");  
            } else {
              alert('something went wrong!')
            }
        
          });
    
    };

  


    React.useEffect(function () {
     
        setValue("check_in", check_in_date);
        setValue("check_out", check_out_date);
        setValue("id", id);
        setValue("userid", user_id);
        
      }, []);









    return (
        <div className="modal-holder ">
            <div className="modal-outer" onClick={() => { closeModal() }}></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">

                <h4 className='sm:text-[24px] text-[18px] text-[#2B2B2B] font-[400]'>Are you sure? You want to cancel this reservation?</h4>




                <form className='mt-8' onSubmit={handleSubmit(onSubmit)} >
                    <div className="flex flex-col gap-4">
                        <div className="popup-field-box">
                        <input
                                {...register("id")}
                                type='hidden'  />

                            <label>Enter Reason</label>
                            <input
                                {...register("reason")}
                                type='text'  />
                            <p className="text-red-500 text-xs italic">
                                {errors.reason?.message}
                            </p>
                        </div>
                        <div className="flex sm:flex-row flex-col gap-6">
                            <div className="popup-field-box">
                                <label>Reservation #</label>
                                <input
                                    {...register("id")}
                                    type='text' readOnly />
                                <p className="text-red-500 text-xs italic">
                                    {errors.userid?.message}
                                </p>
                            </div>
                            <div className="popup-field-box">
                                <label>Check in</label>
                                <input
                                    {...register("check_in")}
                                    type='text' readOnly />
                                <p className="text-red-500 text-xs italic">
                                    {errors.check_in?.message}
                                </p>
                            </div>
                            <div className="popup-field-box">
                                <label>Check out</label>
                                <input
                                    {...register("check_out")}
                                    type='text' readOnly />
                                <p className="text-red-500 text-xs italic">
                                    {errors.check_out?.message}
                                </p>
                            </div>
                        </div>



                        <div className="flex gap-3">
                            <button type='button' onClick={() => { closeModal() }} className='py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]'>Nope</button>
                            <button type="submit" className='bg-[#F38030] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
                            Yes, Cancel
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default CancleReservation

import React from "react";
import { NavLink } from "react-router-dom";
import NotificationBox from "./NotificationBox";
import { useState } from "react";
import { GlobalContext } from "../globalContext";
import { AuthContext } from "../AuthContext";
import { IMAGE_URL } from "../service/api";

import AdminLogo from "../assets/images/admin-logo.svg";
import { GetNotifications } from "../service/AdminApi";

const TopHeader = () => {
  const { user, notifications } = React.useContext(AuthContext).state;
  // const {data:allNotif,loading} = notifications
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [showSide, setShowSide] = useState(false);

  const [showNoti, setShowNoti] = useState(false);
  const [bubble, showBubble] = useState(false);
  const refreshNotification = async () => {
    const allNotifications = await GetNotifications();
    // alert(JSON.stringify(allNotifications))
    const hasAnyUnRead = allNotifications.filter((it) => it.is_read == "0")?.length;
    showBubble(hasAnyUnRead);
    // setIsLoading(false)
  };
  const hasAnyUnRead = false;
  // const hasAnyUnRead = allNotif?.filter(it=>it.is_read=="0")

  React.useEffect(() => {
    refreshNotification();

    globalDispatch({
      type: "MENUBAR",
      payload: {
        menuBar: showSide,
      },
    });
  }, [showSide]);

  const closeNoti = ()=>{
    setShowNoti(false)
  }

  return (
    <>
      <div className="flex justify-between items-center  py-6 relative top-head">
        <div className="flex gap-3 mobile-ham">
          <span
            onClick={() => {
              setShowSide(!showSide);
            }}
          ></span>
          <img className="w-[70px] h-[24px]" src={AdminLogo} />
        </div>
       
        <div className="flex gap-6 items-center ml-auto">
        
          <button
          className={`${bubble? 'bubble-noti' : ''}`}
            type="button"
            onClick={() => {
              setShowNoti(!showNoti);
            }}
          >
            <svg
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.025 4.9035V5.397L11.0243 5.3977C11.0237 5.98686 11.1921 6.56384 11.5094 7.0602L12.285 8.2677C12.9927 9.3695 12.4523 10.8682 11.221 11.2168C10.7946 11.3377 10.3648 11.4426 9.9325 11.5314C9.92847 11.5462 9.92372 11.5609 9.91825 11.5755C9.38377 13.0018 7.95493 14 6.30004 14C4.64514 14 3.21631 13.0018 2.68183 11.5755C2.67636 11.5609 2.67161 11.5462 2.66757 11.5314C2.23531 11.4426 1.80551 11.3377 1.37904 11.2168C0.147743 10.8682 -0.392657 9.3695 0.315044 8.267L1.09064 7.0595C1.40777 6.56306 1.57589 5.98609 1.57504 5.397V4.9035C1.57504 2.1952 3.69184 0 6.30004 0C8.90824 0 11.025 2.1952 11.025 4.9035ZM3.88043 11.7379C4.37642 12.4885 5.26727 13 6.30004 13C7.33281 13 8.22365 12.4885 8.71965 11.7379C7.11395 11.9552 5.48613 11.9552 3.88043 11.7379Z"
                fill="#7A7A7A"
              />
            </svg>
          </button>

          <NavLink to="/profile">
            <img
              src={IMAGE_URL + "/" + user?.avatar}
              className="md:w-[40px] md:h-[40px] h-[24px] w-[24px] object-cover rounded-full"
              alt=""
            />
          </NavLink>
        </div>
        {showNoti && <NotificationBox onClose={closeNoti}  refreshNotification={()=>{
            refreshNotification()
        }} />}
      </div>
    </>
  );
};

export default TopHeader;

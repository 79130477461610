import React from 'react'
import QuotePackBox from './QuotePackBox'
import SedanIcon from '../assets/images/sedan.svg'
import SuvIcon from '../assets/images/suv.svg'
import JeepIcon from '../assets/images/jeep.svg'
import TruckIcon from '../assets/images/pickup.svg'

const CurrentRateBox = ({ blackout, daily_rate, packBoxes, taxCard }) => {
    return (
        <div className="w-full p-[20px] rounded-[20px] bg-white">
            <div className="flex justify-between items-center">
                <h4 className='text-[20px] text-[#1C1C1C] font-[400]'>Current rates and packages</h4>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 1.5L17.74 4.25L15 5.5L17.74 6.76L19 9.5L20.25 6.76L23 5.5L20.25 4.25M9 4.5L6.5 10L1 12.5L6.5 15L9 20.5L11.5 15L17 12.5L11.5 10M19 15.5L17.74 18.24L15 19.5L17.74 20.75L19 23.5L20.25 20.75L23 19.5L20.25 18.24" fill="#F38030" />
                </svg>
            </div>
            {blackout &&
                <div className="w-full flex flex-col  my-4 bg-[#1C1C1C] rounded-[10px] p-[10px]">
                    <p className='text-[#797979] text-[16px] font-[800] uppercase'>Blackout</p>
                    <h4 className='text-[20px] font-[700] text-[white] '>
                        10 April 2024 - 15 April 2024
                    </h4>
                </div>
            }

            {daily_rate &&
                <div className="w-full flex flex-col gap-2 my-4 bg-[#1C1C1C] rounded-[10px] p-[10px] pb-[5px]">
                    <p className='text-[#797979] text-[16px] font-[800] uppercase'>Daily Rate</p>
                    <h4 className='text-[40px] font-[700] text-[white] line-height-100'><span className='text-[18px]'>$</span>{Number(daily_rate).toFixed(2)}</h4>
                </div>
            }
            <div className="flex lg:flex-row flex-col gap-8">
                <div className="w-full ld:max-w-[60%] md:max-w-[100%]">
                    <div className="w-full bg-[#F3F3F3] rounded-[10px] p-[10px]">
                        <h5 className='text-[16px] text-[#797979] font-[800]'>Quote packages</h5>
                        <div className="flex gap-2 mt-2 flex-col">
                            {packBoxes.map((item) => (
                                <QuotePackBox PackTitle={item?.name} Price={Number(item?.price).toFixed(2)} Tooltip={item.nick_name} 
                                
                                PackIcon={item.icon === 'sedan' ? SedanIcon : item.icon === 'suv' ? SuvIcon : item.icon === 'jeep' ? JeepIcon : item.icon === 'truck' ? TruckIcon : ''}
                            
                                />

                            ))}
                            

                        </div>
                    </div>
                </div>
                <div className="w-full lg:max-w-[40%] md:max-w-[100%]">
                    <div className="w-full bg-[#F3F3F3] rounded-[10px] p-[10px]">
                        <h5 className='text-[16px] text-[#797979] font-[800]'>Taxes and fees</h5>
                        <div className="flex gap-2 mt-2 flex-col">
                        {taxCard.map((item) => (
                                <QuotePackBox PackTitle={item.name} hideCurrency={item.amount=="0"} Price={item.amount=="0"?Number(item.percentage).toFixed(2):Number(item.amount).toFixed(2)} />

                            ))}
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CurrentRateBox

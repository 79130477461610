import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GlobalContext, showToast } from "../globalContext";
import { AuthContext } from "../AuthContext";
import { editProfile } from "../service/AdminApi";
import { GetProfileName } from "../service/AdminApi";
import { BASE_URL, IMAGE_URL } from "../service/api";

const ProfileSettings = () => {
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  const { dispatch } = React.useContext(AuthContext);
  const { state } = React.useContext(GlobalContext);
  const { user } = React.useContext(AuthContext).state;

  const [btnLoading, setBtnLoading] = useState(false)

  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const schema = yup.object({
    avatar: yup.string(),
    first_name: yup.string().required(),
    password: yup.string(),
    last_name: yup.string().required(),
    user_name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.number(),
    id: yup.number(),
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setBtnLoading(true)
    data["profile_image"] = selectedImage;
    editProfile(data, (success, resp) => {
      if (success) {
        // getData();
        if (success) {
          dispatch({
            type: "USERDATA",
            payload: {
              user: resp,
            },
          });
          showToast(globalDispatch, "Profile Updated");
          setBtnLoading(false)
        }
      } else {
        alert("something went wrong!");
      }
    });
  };

  // const [profileDetails, setProfileDetails] = useState([]);

  const getData = async () => {
    //   alert('.')
    const profileData = await GetProfileName();

    dispatch({
      type: "USERDATA",
      payload: {
        user: profileData,
      },
    });
  };

  useEffect(function () {
    setValue("first_name", user?.first_name);
    setValue("avatar", user?.avatar); // how to upload image from file input
    setValue("last_name", user?.last_name);
    setValue("user_name", user?.user_name);
    setValue("email", user?.email);
    setValue("phone_number", user?.phone_number);
    setValue("id", user?.id);
   
    setImagePreview(IMAGE_URL + "/" + user?.avatar);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setValue("avatar", file);
    setSelectedImage(file);
    // console.log(file);
    // Create a preview URL for the image
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(null);
    }
  };
  return (
    <>
      <div className="rounded-[20px] bg-[#FFFFFF] p-6">
        <div className="w-fit flex justify-center items-center relative flex-col">
          {/* Show Preview (if available) */}
          {imagePreview ? (
            <img
              className="w-[100px] h-[100px] rounded-full object-cover"
              src={imagePreview}
              alt="Preview"
            />
          ) : (
            <img
              className="w-[100px] h-[100px] rounded-full object-cover"
              src={IMAGE_URL + "/" + user?.avatar}
              alt=""
            />
          )}
          {/* <img
            className="w-[100px] h-[100px] rounded-full object-cover"
            src={IMAGE_URL + "/" + user?.avatar}
            alt=""
          /> */}
          <button
            className="text-[#F38030] text-[12px] font-[400] border-b border-[#F38030]"
            type="button"
          >
            Change img
          </button>

          <input
            className="absolute bottom-0 left-0 w-[100px] opacity-0 cursor-pointer"
            {...register("avatar")}
            type="file"
            onChange={handleImageChange} // Add onChange
          />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 profile-form"
        >
          <div className="block">
            <input {...register("id")} type="hidden" />

            <input
              {...register("first_name")}
              type="text"
              placeholder="First name"
            />
            <p className="text-red-500 text-xs italic">
              {errors.first_name?.message}
            </p>
          </div>
          <div className="block">
            <input
              {...register("last_name")}
              type="text"
              placeholder="Last name"
            />
            <p className="text-red-500 text-xs italic">
              {errors.last_name?.message}
            </p>
          </div>
          <div className="block">
            <input
              {...register("user_name")}
              type="text"
              placeholder="User name"
            />
            <p className="text-red-500 text-xs italic">
              {errors.user_name?.message}
            </p>
          </div>
          <div className="block">
            <input {...register("email")} type="email" placeholder="Email" />
            <p className="text-red-500 text-xs italic">
              {errors.email?.message}
            </p>
          </div>
          <div className="block">
            <input
              {...register("password")}
              type="text"
              placeholder="password"
            />
            <p className="text-red-500 text-xs italic">
              {errors.email?.message}
            </p>
          </div>
          <div className="block">
            <input
              {...register("phone_number")}
              type="text"
              placeholder="Phone number"
            />
            <p className="text-red-500 text-xs italic">
              {errors.phone_number?.message}
            </p>
          </div>

          <button className={`${btnLoading? 'opacity-5 pointer-events-none' : ''}`} type="submit">Save Changes</button>
        </form>
      </div>
    </>
  );
};

export default ProfileSettings;

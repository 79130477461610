import React from 'react'
import { useState } from 'react';
import PageTitle from '../admin-components/PageTitle'
import { useEffect } from 'react';
import { GlobalContext } from "../globalContext";
import FaqQuestionBoxes from '../admin-components/FaqQuestionBoxes';

const Calender = () => {

    const { dispatch: globalDispatch } = React.useContext(GlobalContext);


    React.useEffect(() => {


        globalDispatch({
            type: "SETPATH",
            payload: {
                path: "calender",
            },
        });

        globalDispatch({
            type: "SETPAGENAME",
            payload: {
                pageName: "Calender",
            },
        });


        globalDispatch({
            type: "MENUBAR",
            payload: {
                menuBar: false,
            },

        });

    }, []);
    return (
        <div>
            <PageTitle />
        </div>
    )
}

export default Calender

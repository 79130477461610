import moment from "moment";
import { api, BASE_URL } from "./api";
import axios from "axios";




// faq



export const createFaq = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + "/admin/faq", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      question: params.question,
      answer: params.answer,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};




export const getAdminFaq = async () => {
  const resp = await api.get("/admin/faq");
  return resp.data;
};


export const deleteFaq = (id, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/faq/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify({
    //   airport_id: "1",
    // }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};



export const editFaq = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/faq/${params.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      question: params.question,
      answer: params.answer,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};



// email notes

export const getEmailNote = async () => {
  const resp = await api.get("/admin/email-note");
  return resp.data;
};


export const AddEmailNotes = (emailNote, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + "/admin/email-note", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      note: { emailNote },
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};





// packages

export const createPackage = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + "/admin/packages", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      icon: params.icon,
      name: params.title,
      nick_name: params.nickname,
      amount: params.amount,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};
export const editPackage = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/packages/${params.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      icon: params.icon,
      name: params.title,
      nick_name: params.nickname,
      amount: params.amount,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const depositFees = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/settings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      key: "deposit_fee",
      value: params.value2,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const dailyRates = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/settings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      key: "daily_rate",
      value: params.value,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const UpdatePackageStatus = (statusParams, onResponse) => {
  const token = localStorage.getItem("token");

  fetch(BASE_URL + `/admin/packages/${statusParams.id}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      status: statusParams.status + "",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};
export const deletePackage = (id, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/packages/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const getAdminPackages = async () => {
  const resp = await api.get("/admin/packages?per_page=10&page=1&airport_id=1");
  return resp.data;
};

export const getDeposiFee = async () => {
  const resp = await api.get("/admin/settings/deposit_fee?airport_id=1");

  return resp.data;
};

export const getDailyRates = async () => {
  const resp = await api.get("/admin/settings/daily_rate?airport_id=1");

  return resp.data;
};

// taxes

export const addTax = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + "/admin/taxes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      // is_percentage: params.is_percentage+"",
      // amount: params.amount? params.amount : null,
      // percentage: params.percentage? params.percentage : null,
      amount: params.type === "0" ? params.amount || 0 : 0,
      percentage: params.type === "1" ? params.percentage || 0 : 0,
      name: params.name,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const deleteTax = (id, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/taxes/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const EditTax = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/taxes/${params.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      // is_percentage: params.is_percentage+"",
      amount: params.type === "0" ? params.amount || 0 : 0,
      percentage: params.type === "1" ? params.percentage || 0 : 0,
      name: params.name,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const getAdminTaxes = async () => {

  const resp = await api.get("/admin/taxes?per_page=50&page=1&airport_id=1");

  return resp.data;
};


export const getDashboardData = async () => {

  const resp = await api.get("/admin/dashboard?airport_id=1");

  return resp.data;
};

export const UpdateTaxStatus = (statusParams, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/taxes/${statusParams.id}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      status: statusParams.status + "",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

// coupons

export const addCoupon = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + "/admin/coupons", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },

    body: JSON.stringify({
      airport_id: "1",
      name: params.coupon_title,
      description: params.coupon_title,
      code: params.coupon_code,
      amount: params.type === '0' ? (params.amount || 0) : 0,
      percentage: params.type === "1" ? params.percentage || 0 : 0,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const editCoupon = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/coupons/${params.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      name: params.coupon_title,
      description: params.coupon_title,
      code: params.coupon_code,
      amount: params.type === '0' ? (params.amount || 0) : 0,
      percentage: params.type === "1" ? params.percentage || 0 : 0,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const UpdateCouponStatus = (statusParams, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/coupons/${statusParams.id}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      status: statusParams.status + "",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};
export const deleteCoupon = (id, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/coupons/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const getAdminCoupons = async () => {
  const resp = await api.get("/admin/coupons?per_page=50&page=1&airport_id=1");
  return resp.data;
};

// reservation

export const getReservationInfo = async () => {
  // const resp = await api.get("/admin/reservation/daily/info?airport_id=1");
  let token = localStorage.getItem("token");
  const response = await fetch(BASE_URL + `/admin/reservation/daily/info?airport_id=1`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();

  return data;

};
export const getAdminReservations = async (page, search, date, filter) => {
  // const resp = await api.get("/admin/reservation?airport_id=1");
  let token = localStorage.getItem("token");

  let pageData = ''
  if (page) {
    pageData = '&page=' + page
  }
  if (search) {
    pageData += `&q=${search}`;
  }
  if (date) {
    pageData += `&date=${date}`;
  }
  if (filter) {
    pageData += `&filter_by=${filter}`
  }
  const response = await fetch(BASE_URL + `/admin/reservation?airport_id=1&per_page=10${pageData}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();

  return data;
};

export const cancleReserv = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/reservation/${params.id}/cancel`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: "1",
      reason: params.reason,
      status: "cancel",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

// profile

export const GetProfileName = async (newToken) => {
  let token = undefined;
  if (newToken) token = newToken;
  else token = localStorage.getItem("token");
  // alert(token)
  const response = await fetch(BASE_URL + `/admin/profile`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();

  return data;

};


export const editProfile = (params, onResponse) => {
  let token = localStorage.getItem("token");

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("first_name", params.first_name);
  formdata.append("last_name", params.last_name);
  formdata.append("user_name", params.user_name);
  formdata.append("email", "admin@gmail.com");
  formdata.append("role", "Super Admin");
  {
    params.password &&
      formdata.append("password", params.password);
  }
  formdata.append("phone_number", params.phone_number);
  if (params.profile_image) { formdata.append('avatar', params.profile_image, params.profile_image.name); }
  // formdata.append('avatar', params.profile_image, params.profile_image.name);
  // formdata.append("avatar", fileInput.files[0], "04_display_large.jpg");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  fetch("https://parkndepart.io/backend/public/api/admin/profile", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      onResponse(true, result)
    })
    .catch((error) => console.error(error));


};

// notification

export const GetNotifications = async () => {
  const resp = await api.get("/admin/notifications?airport_id=1");
  return resp.data;
};


export const MarkRead = async (id) => {

  const resp = await api.put(`/admin/notifications/read?airport_id=1&id=${id}`);
  return resp.data;

};

export const MarkALLRead = async (id) => {

  const resp = await api.put(`/admin/notifications/read?airport_id=1`);
  return resp.data;

};



// bussines page


export const GetHours = async () => {
  const resp = await api.get("/admin/business-information");
  return resp.data;
};


export const editHours = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/business-information`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      id: params.id,
      email: params.email,
      phone_number: params.phone_number,
      location: params.location,
      monday_start_time: params.monday_start_time,
      monday_end_time: params.monday_end_time,
      tuesday_start_time: params.tuesday_start_time,
      tuesday_end_time: params.tuesday_end_time,
      wednesday_start_time: params.wednesday_start_time,
      wednesday_end_time: params.wednesday_end_time,
      thursday_start_time: params.thursday_start_time,
      thursday_end_time: params.thursday_end_time,
      friday_start_time: params.friday_start_time,
      friday_end_time: params.friday_end_time,
      saturday_start_time: params.saturday_start_time,
      saturday_end_time: params.saturday_end_time,
      sunday_start_time: params.sunday_start_time,
      sunday_end_time: params.sunday_end_time,

    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }

    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};


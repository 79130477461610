import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { editPackage } from '../service/AdminApi';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SedanIcon from '../assets/images/sedan.svg'
import SuvIcon from '../assets/images/suv.svg'
import JeepIcon from '../assets/images/jeep.svg'
import TruckIcon from '../assets/images/pickup.svg'
import ValetIcon from '../assets/images/valet.svg'
import SelfParkIcon from '../assets/images/self-park.svg'
import { GlobalContext, showToast } from "../globalContext";

const EditPackage = ({closeModal,PackName,PackPrice,PackIcon,NickName,id, PackIconName, getData}) => {
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  const [iconsSelector, setIconSelector] = useState(false)
  const [iconName, setIconName] = useState(PackIconName)


  const schema = yup
  .object({
    id:yup.number().required(),
    title: yup.string().required(),
    icon: yup.string().required(),
    nickname: yup.string(),
    amount: yup.number().required(),
  })


const {
  register,
  handleSubmit,
  setError,
  setValue,
  formState: { errors },
} = useForm({
  resolver: yupResolver(schema),
});

const onSubmit = async (data) => {
  editPackage(data, (success, resp) => {
    if (success) {
   
      showToast(globalDispatch, "Updated");

      getData()
      closeModal()

    } else {
      alert('something went wrong!')
    }

  });
};

useEffect(function () {

  setValue("icon", iconName);
}, [iconName]);

useEffect(function () {

  setValue("title", PackName);
  setValue("id", id);
  setValue("nickname", NickName);
  setValue("amount", PackPrice);
  setValue("icon", PackIconName);
}, []);



  return (
    <>

{iconsSelector &&
        <div className="modal-holder z-999999">
          <div
            className="modal-outer"
            onClick={() => {
              closeModal();
            }}
          ></div>
          <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
            <div className="flex items-center justify-between">
              <h4 className="md:text-[24px] text-[18px] text-[#2B2B2B] font-[400]">{iconName ? 'Change Icon' : 'Select Icon'}</h4>
            </div>

            <div className="mt-8">

              <div className="grid lg:grid-cols-4 md:grid-cols-3 md:gap-5 grid-cols-2 gap-3 select-icon-holder">
                <button className={` ${iconName === 'sedan' ? 'active' : ''}`} type="button"
                  onClick={() => {
                    setIconName('sedan')
                    setIconSelector(false)
                  }}
                >
                  <img src={SedanIcon} alt="" />
                  <p>Sedan</p>
                </button>
                <button className={` ${iconName === 'suv' ? 'active' : ''}`} type="button"
                  onClick={() => {
                    setIconName('suv')
                    setIconSelector(false)
                  }}
                >
                  <img src={SuvIcon} alt="" />
                  <p>SUV</p>
                </button>
                <button className={` ${iconName === 'jeep' ? 'active' : ''}`} type="button"
                  onClick={() => {
                    setIconName('jeep')
                    setIconSelector(false)
                  }}
                >
                  <img src={JeepIcon} alt="" />
                  <p>Jeep</p>
                </button>
                <button className={` ${iconName === 'truck' ? 'active' : ''}`} type="button"
                  onClick={() => {
                    setIconName('truck')
                    setIconSelector(false)
                  }}
                >
                  <img src={TruckIcon} alt="" />
                  <p>Truck</p>
                </button>
                <button className={` ${iconName === 'valet' ? 'active' : ''}`} type="button"
                  onClick={() => {
                    setIconName('valet')
                    setIconSelector(false)
                  }}
                >
                  <img src={ValetIcon} alt="" />
                  <p>Valet</p>
                </button>
                <button className={` ${iconName === 'self-park' ? 'active' : ''}`} type="button"
                  onClick={() => {
                    setIconName('self-park')
                    setIconSelector(false)
                  }}
                >
                  <img src={SelfParkIcon} alt="" />
                  <p>Self Park</p>
                </button>
              </div>
            </div>

          </div>
        </div>
      }

    <div className="modal-holder">
    <div className="modal-outer" onClick={() => { closeModal() }}></div>
    <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
      <div className="flex items-center justify-between">
      <h4 className='text-[24px] text-[#2B2B2B] font-[400]'>Edit {PackName} Package:</h4>
      <button
       onClick={() => {
        setIconSelector(true)
      }}
      type='button' className='py-[10px] px-[10px] flex items-center gap-2 rounded-[10px] bg-[#F3F3F3] text-[#1C1C1C] text-[12px] font-[800]'> {iconName ? 'Change Icon' : 'Select Icon'} <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.41536 9.83398V5.07982L4.8987 6.59648L4.08203 5.75065L6.9987 2.83398L9.91536 5.75065L9.0987 6.59648L7.58203 5.07982V9.83398H6.41536ZM3.4987 12.1673C3.17786 12.1673 2.90331 12.0532 2.67503 11.8249C2.44675 11.5966 2.33242 11.3219 2.33203 11.0007V9.25065H3.4987V11.0007H10.4987V9.25065H11.6654V11.0007C11.6654 11.3215 11.5512 11.5962 11.3229 11.8249C11.0947 12.0536 10.8199 12.1677 10.4987 12.1673H3.4987Z" fill="#1C1C1C" />
      </svg>
      </button>
      </div>


      <form className='mt-8' onSubmit={handleSubmit(onSubmit)} >
        <div className="flex flex-col gap-4">
          <div className="popup-field-box">
          <input {...register("id")} type="hidden" />
          <input {...register("icon")} type="hidden" />
            <label>Add New Package</label>
            <input
              {...register("title")}
              type='text' />
            <p className="text-red-500 text-xs italic">
              {errors.title?.message}
            </p>
          </div>
          <div className="popup-field-box">
            <label>Enter Nickname</label>
            <input
              {...register("nickname")}
              type='text' />
            <p className="text-red-500 text-xs italic">
              {errors.nickname?.message}
            </p>
          </div>
          <div className="popup-field-box">
            <label>Enter Amount</label>
            <input
              {...register("amount")}
              type='text' />
            <p className="text-red-500 text-xs italic">
              {errors.amount?.message}
            </p>
          </div>


          <div className="flex gap-3">
            <button type='button' onClick={() => { closeModal() }} className='py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]'>Cancel</button>
            <button type="submit" className='bg-[#F38030] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
              Add
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>

  </>
  )
}

export default EditPackage

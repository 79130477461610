import React from 'react'
import { useState } from 'react'
import { useCallback } from "react";
import CancleReservation from './CancleReservation';
import moment from 'moment';

const RevervationListCard = ({
    getData,
    id,
    user_id,
    user_name,
    email,
    phone_number,
    car_make,
    car_model,
    car_color,
    airport_id,
    airport_name,
    coupon_id,
    coupon_name,
    coupon_code,
    coupon_percentage,
    package_id,
    package_name,
    package_nick_name,
    package_amount,
    check_in,
    check_out,
    sub_total,
    discount,
    total,
    due_now,
    payment_method_name,
    payment_transaction_id,
    payment_card_name,
    payment_card_number,
    payment_card_expiry,
    payment_card_cvc,
    status,
    cancel_reason,
    created_at,
    updated_at,
    date,
    search,
    filter
}) => {

    const [showOptions, setShowOptions] = useState(false)


    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const onCloseDeleteModal = useCallback(() => {
        setShowDeleteModal(false);
        setShowOptions(false)
    }, [showDeleteModal]);

    return (
        <div className=' relative  max-[100%] w-[100%]'>

            {showDeleteModal &&
                <CancleReservation getData={getData} date={date} search={search} filter={filter} id={id} user_id={user_id} check_in_date={check_in} check_out_date={check_out} closeModal={onCloseDeleteModal} />
            }
            <div
                onClick={() => {
                    setShowOptions(false)
                }}
                className={` pr-[50px] reservation-list-card-holder flex items-center gap-6 border justify-between rounded-[10px] py-4 px-6 max-[100%] w-[100%] border-[#E7E7E7]`}>
                <div className="flex items-center gap-8 max-[100%] w-[100%]">
                    <div className="flex flex-col min-w-[160px]">
                        <h3 className='flex items-center gap-3 text-[#1C1C1C] font-[800] text-[18px]'>PND{id}
                            {status === 'cancel' ?
                                <span className='block w-fit bg-[#FFE5E5] rounded-[25px] py-1 px-2 text-[#FF0000] text-[12px]'>Cancelled</span>
                                :
                                ''
                            }
                        </h3>

                        {/* <p className='flex text-[12px] text-[#1C1C1C] items-center gap-1'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.33333 7.33398L4.33333 4.33398H11.6667L12.6667 7.33398M11.6667 10.6673C11.4015 10.6673 11.1471 10.562 10.9596 10.3744C10.772 10.1869 10.6667 9.93253 10.6667 9.66732C10.6667 9.4021 10.772 9.14775 10.9596 8.96021C11.1471 8.77267 11.4015 8.66732 11.6667 8.66732C11.9319 8.66732 12.1862 8.77267 12.3738 8.96021C12.5613 9.14775 12.6667 9.4021 12.6667 9.66732C12.6667 9.93253 12.5613 10.1869 12.3738 10.3744C12.1862 10.562 11.9319 10.6673 11.6667 10.6673ZM4.33333 10.6673C4.06812 10.6673 3.81376 10.562 3.62623 10.3744C3.43869 10.1869 3.33333 9.93253 3.33333 9.66732C3.33333 9.4021 3.43869 9.14775 3.62623 8.96021C3.81376 8.77267 4.06812 8.66732 4.33333 8.66732C4.59855 8.66732 4.8529 8.77267 5.04044 8.96021C5.22798 9.14775 5.33333 9.4021 5.33333 9.66732C5.33333 9.93253 5.22798 10.1869 5.04044 10.3744C4.8529 10.562 4.59855 10.6673 4.33333 10.6673ZM12.6133 4.00065C12.48 3.61398 12.1067 3.33398 11.6667 3.33398H4.33333C3.89333 3.33398 3.52 3.61398 3.38667 4.00065L2 8.00065V13.334C2 13.5108 2.07024 13.6804 2.19526 13.8054C2.32029 13.9304 2.48986 14.0007 2.66667 14.0007H3.33333C3.51014 14.0007 3.67971 13.9304 3.80474 13.8054C3.92976 13.6804 4 13.5108 4 13.334V12.6673H12V13.334C12 13.5108 12.0702 13.6804 12.1953 13.8054C12.3203 13.9304 12.4899 14.0007 12.6667 14.0007H13.3333C13.5101 14.0007 13.6797 13.9304 13.8047 13.8054C13.9298 13.6804 14 13.5108 14 13.334V8.00065L12.6133 4.00065Z" fill="#F38030" />
                        </svg>
                            {car_make} {car_model} - {car_color} </p> */}
                    </div>
                    <div className="flex flex-col ">
                        <div className="flex items-center gap-2">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="20" height="20" rx="10" fill="#FEF3EB" />
                                <path d="M10.5 7C10.9641 7 11.4092 7.18437 11.7374 7.51256C12.0656 7.84075 12.25 8.28587 12.25 8.75C12.25 9.21413 12.0656 9.65925 11.7374 9.98744C11.4092 10.3156 10.9641 10.5 10.5 10.5C10.0359 10.5 9.59075 10.3156 9.26256 9.98744C8.93437 9.65925 8.75 9.21413 8.75 8.75C8.75 8.28587 8.93437 7.84075 9.26256 7.51256C9.59075 7.18437 10.0359 7 10.5 7ZM10.5 11.375C12.4338 11.375 14 12.1581 14 13.125V14H7V13.125C7 12.1581 8.56625 11.375 10.5 11.375Z" fill="#F38030" />
                            </svg>

                            <div className="block min-w-[140px]">
                                <h3 className='text-[#1C1C1C] font-[800] text-[14px]'>{user_name}</h3>
                                <p className='flex items-center gap-1'>
                                    {phone_number}</p>
                                <span className='flex items-center text-[10px]'>
                                    {email}
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="block w-[1px] h-[55px] bg-[#E3E3E3]"></div>
                <div className="flex flex-col text-center justify-center items-center gap-2 min-w-[300px]">
                    <p className='text-[#1C1C1C] text-[14px] gap-2 font-[400] flex items-center'>
                        {/* Assuming check_in is a valid date string/object*/}
                        {moment(check_in).format('MMMM Do, YYYY h:mm a')}
                        <span className='block w-fit bg-[#F38030] rounded-[20px] py-1 text-[12px] text-[#FFFFFF] px-3'>Check in</span>
                    </p>
                    <p className='text-[#1C1C1C] text-[14px] gap-2 font-[400] flex items-center'>
                        {/* Assuming check_out is a valid date string/object */}
                        {moment(check_out).format('MMMM Do, YYYY h:mm a')}
                        <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[12px] text-[#F38030] px-3'>Check Out</span>
                    </p>
                </div>
                <div className="block w-[1px] h-[55px] bg-[#E3E3E3]"></div>
                <div className="flex gap-8">
                {/* {package_name && <div className="flex flex-col justify-center items-center">
                        <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[14px] text-[#F38030] px-3'>Package</span>
                        <h4 className='text-[#1C1C1C] font-[800] text-[16px]'>{package_name}</h4>
                    </div>} */}
                    {package_amount &&
                        <div className="flex flex-col justify-center items-center">
                            <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[14px] text-[#F38030] px-3'>Package Amount</span>
                            <h4 className='text-[#1C1C1C] font-[800] text-[16px]'>${package_amount}</h4>
                        </div>
                    }
                    {sub_total && <div className="flex flex-col justify-center items-center">
                        <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[14px] text-[#F38030] px-3'>Subtotal</span>
                        <h4 className='text-[#1C1C1C] font-[800] text-[16px]'>${sub_total}</h4>
                    </div>
                    }
                   
                  
                    {discount &&
                        <div className="flex flex-col justify-center items-center">
                            <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[14px] text-[#F38030] px-3'>Discount</span>
                            <h4 className='text-[#1C1C1C] font-[800] text-[16px]'>${discount}</h4>
                        </div>
                    }
                     <div className="flex flex-col justify-center items-center">
                            <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[14px] text-[#F38030] px-3'>S/C</span>
                            <h4 className='text-[#1C1C1C] font-[800] text-[16px]'>5.95</h4>
                        </div>
                    {due_now &&
                        <div className="flex flex-col justify-center items-center">
                            <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[14px] text-[#F38030] px-3'>Pay at Lot</span>
                            <h4 className='text-[#1C1C1C] font-[800] text-[16px]'>${due_now}</h4>
                        </div>
                    }
                    {total &&
                        <div className="flex flex-col justify-center items-center">
                            <span className='block w-fit bg-[#FEF2EA] rounded-[20px] py-1 text-[14px] text-[#F38030] px-3'>Total</span>
                            <h4 className='text-[#1C1C1C] font-[800] text-[16px]'>${total}</h4>
                        </div>
                    }
                </div>





            </div>

            {status === 'cancel' ?
                <></>
                :
                <div className="reservation-list-option-btn"
                    onClick={() => {
                        setShowOptions(!showOptions)
                    }}
                >
                    <button type='button' className='bg-[#F2F2F2] w-[24px] h-[24px] rounded-[5px] flex items-center justify-center'>
                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8339 1.91649C2.83396 2.10257 2.79737 2.28684 2.72621 2.45877C2.65506 2.63071 2.55074 2.78694 2.4192 2.91856C2.28767 3.05018 2.1315 3.1546 1.95961 3.22586C1.78772 3.29713 1.60348 3.33384 1.4174 3.3339C1.23133 3.33396 1.04706 3.29737 0.875126 3.22621C0.703191 3.15506 0.546954 3.05074 0.415336 2.9192C0.283718 2.78767 0.179297 2.6315 0.108033 2.45961C0.0367699 2.28772 5.98432e-05 2.10348 7.32043e-08 1.9174C-0.000120638 1.54161 0.149049 1.18115 0.414693 0.915337C0.680337 0.649522 1.04069 0.500121 1.41649 0.5C1.79229 0.499879 2.15275 0.649049 2.41856 0.914693C2.68437 1.18034 2.83378 1.5407 2.8339 1.91649Z" fill="#7A7A7A" />
                            <path d="M1.41649 8.19627C2.1988 8.19627 2.83299 7.56208 2.83299 6.77978C2.83299 5.99747 2.1988 5.36328 1.41649 5.36328C0.634185 5.36328 0 5.99747 0 6.77978C0 7.56208 0.634185 8.19627 1.41649 8.19627Z" fill="#7A7A7A" />
                            <path d="M1.41649 13.0595C2.1988 13.0595 2.83299 12.4254 2.83299 11.6431C2.83299 10.8607 2.1988 10.2266 1.41649 10.2266C0.634185 10.2266 0 10.8607 0 11.6431C0 12.4254 0.634185 13.0595 1.41649 13.0595Z" fill="#7A7A7A" />
                        </svg>

                    </button>
                </div>

            }

            {showOptions &&
                <div className="reservation-actions">
                    {/* <button className='text-[#1C1C1C] rounded-[5px] bg-[white] py-2 w-[190px] text-center text-[16px] font-[500]' type='button'>View Reservation</button> */}
                    <button
                        onClick={() => {
                            setShowDeleteModal(true)
                        }}
                        className={`text-[white] items-center pl-2 flex gap-2 rounded-[5px] bg-[#F38030] py-2 w-[190px] text-center text-[16px] font-[500]`} type='button'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5987 11.334L7.9987 8.93398L10.3987 11.334L11.332 10.4007L8.93203 8.00065L11.332 5.60065L10.3987 4.66732L7.9987 7.06732L5.5987 4.66732L4.66536 5.60065L7.06536 8.00065L4.66536 10.4007L5.5987 11.334ZM7.9987 14.6673C7.07648 14.6673 6.20981 14.4922 5.3987 14.142C4.58759 13.7918 3.88203 13.3169 3.28203 12.7173C2.68203 12.1173 2.20714 11.4118 1.85736 10.6006C1.50759 9.78954 1.33248 8.92287 1.33203 8.00065C1.33203 7.07843 1.50714 6.21176 1.85736 5.40065C2.20759 4.58954 2.68248 3.88398 3.28203 3.28398C3.88203 2.68398 4.58759 2.2091 5.3987 1.85932C6.20981 1.50954 7.07648 1.33443 7.9987 1.33398C8.92092 1.33398 9.78759 1.5091 10.5987 1.85932C11.4098 2.20954 12.1154 2.68443 12.7154 3.28398C13.3154 3.88398 13.7905 4.58954 14.1407 5.40065C14.4909 6.21176 14.6658 7.07843 14.6654 8.00065C14.6654 8.92287 14.4903 9.78954 14.14 10.6006C13.7898 11.4118 13.3149 12.1173 12.7154 12.7173C12.1154 13.3173 11.4098 13.7924 10.5987 14.1426C9.78759 14.4929 8.92092 14.6678 7.9987 14.6673Z" fill="white" />
                        </svg>
                        Cancel Reservation</button>
                </div>
            }
        </div>
    )
}

export default RevervationListCard

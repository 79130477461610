import React from 'react'
import ReservRevenuBox from './ReservRevenuBox'

const ReservationInfoBox = ({today,weekly, monthy}) => {
    return (
        <div className="w-full p-[20px] rounded-[20px] bg-white">
            <div className="flex justify-between items-center">
                <h4 className='md:text-[20px] text-[16px] text-[#1C1C1C] font-[400]'>Reservations</h4>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 19.5H5V8.5H19M16 1.5V3.5H8V1.5H6V3.5H5C3.89 3.5 3 4.39 3 5.5V19.5C3 20.0304 3.21071 20.5391 3.58579 20.9142C3.96086 21.2893 4.46957 21.5 5 21.5H19C19.5304 21.5 20.0391 21.2893 20.4142 20.9142C20.7893 20.5391 21 20.0304 21 19.5V5.5C21 4.96957 20.7893 4.46086 20.4142 4.08579C20.0391 3.71071 19.5304 3.5 19 3.5H18V1.5M17 12.5H12V17.5H17V12.5Z" fill="#F38030" />
                </svg>
            </div>

            <div className="flex gap-4 flex-col mt-4">
                <ReservRevenuBox Count={today} BoxTag='Today' />
                <ReservRevenuBox Count={weekly} BoxTag='This Week'  />
                <ReservRevenuBox Count={monthy} BoxTag='This Month'  />
            </div>
        </div>
    )
}

export default ReservationInfoBox

import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditTax } from '../service/AdminApi';
import * as yup from "yup";

import { GlobalContext, showToast } from "../globalContext";

const EditTaxes = ({ closeModal, DiscountName,id,amount, OffPercentage,getData }) => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [isPercentage, setIsPercentage] = useState(OffPercentage !== '0'? '1' : '0')

    const schema = yup
        .object({
            name: yup.string().required(),
            // is_percentage: yup.string(),
            type: yup.string().required(),
            ...(isPercentage === '1' && {
                percentage: yup.number().max(100, 'Value must not exceed 100'),
            }),
            ...(isPercentage === '0' && {
                amount: yup.string(),  // Add validation for amount if needed
            }),
            id:yup.number(),

        })
        .required();

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        EditTax(data, (success, resp) => {
            if (success) {
                getData()
                closeModal()
                showToast(globalDispatch, "Tax Edited");
            } else {
                alert('something went wrong!')
            }

        });
    };
   

    useEffect(function () {
        setValue("name", DiscountName);
        // setValue("is_percentage", OffPercentage !== null? OffPercentage : null);
        setValue("type", OffPercentage !== '0'? '1' : '0');
        setValue("amount", amount);
        setValue("percentage", OffPercentage);
        setValue("id", id);
      }, []);

    return (
        <div className="modal-holder">
            <div className="modal-outer" onClick={() => { closeModal() }}></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
                <h4 className='md:text-[24px] text-[18px] text-[#2B2B2B] font-[400]'>Edit {DiscountName}</h4>


                <form className='mt-8' onSubmit={handleSubmit(onSubmit)} >
                    <div className="flex flex-col gap-4">
                    <input
                                {...register("id")}
                                type='hidden' />
                        <div className="popup-field-box">
                            <label>Enter Title</label>
                            <input
                                {...register("name")}
                                type='text' />
                            <p className="text-red-500 text-xs italic">
                                {errors.name?.message}
                            </p>
                        </div>

                        <div className="popup-field-box">
                            <label>Select charge type</label>
                            <input type='hidden'
                                {...register("type")}
                                value={isPercentage}
                            />
                            {/* <input type='text'
                                {...register("is_percentage")}
                                value={isPercentage}
                            /> */}

                            <div className="percent-field-holder">
                                <select
                                    onChange={(e) => {
                                        setValue("type", e.target.value);
                                        setValue("is_percentage", e.target.value);

                                        setIsPercentage(e.target.value)
                                    }}
                                    value={isPercentage}
                                >
                                    <option value='1'>Percentage</option>
                                    <option value='0'>Flat</option>
                                </select>
                                {isPercentage === '1' ?
                                    <input
                                        {...register("percentage")}
                                        placeholder='0.00%'
                                        type='text' />
                                    :
                                    <></>
                                }
                                {isPercentage === '0' ?
                                    <input
                                        {...register("amount")}
                                        placeholder='$20'
                                        type='text' />
                                    :
                                    <></>
                                }


                            </div>
                            <p className="text-red-500 text-xs italic">
                                {errors.percentage?.message}
                            </p>
                            <p className="text-red-500 text-xs italic">
                                {errors.amount?.message}
                            </p>
                        </div>
                        <p className="text-red-500 text-xs italic">
                            {errors.charge?.message}
                        </p>




                        <div className="flex gap-3">
                            <button type='button' onClick={() => { closeModal() }} className='py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]'>Cancel</button>
                            <button type="submit" className='bg-[#F38030] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
                                Add
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default EditTaxes

import React from 'react'
import { useState } from 'react';
import PageTitle from '../admin-components/PageTitle'
import { useEffect } from 'react';
import { GlobalContext } from "../globalContext";
import OperatingHoursBox from '../admin-components/OperatingHoursBox';

const OperatingHours = () => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    React.useEffect(() => {


        globalDispatch({
            type: "SETPATH",
            payload: {
                path: "business",
            },
        });

        globalDispatch({
            type: "SETPAGENAME",
            payload: {
                pageName: "Business Information",
            },
        });


        globalDispatch({
            type: "MENUBAR",
            payload: {
                menuBar: false,
            },

        });

    }, []);


    return (
        <>
            <PageTitle />
         

                    <OperatingHoursBox />

              
        </>
    )
}

export default OperatingHours

import React from 'react'

const QuotePackBox = ({ PackTitle, Price, PackIcon, Tooltip, hideCurrency }) => {
  return (
    <div className="w-full rounded-[10px] border-[1px] bg-[white] border-[#C5C5C5] p-[20px] flex justify-between items-center">
      <div className="flex flex-col gap-4">
        <h4 className='flex break-all items-center gap-1 uppercase  md:text-[20px] text-[16px] text-[#F38030] font-[700]' title={Tooltip ? Tooltip : undefined}>
          {PackTitle}
          {Tooltip &&
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.16797 14.666H10.8346V9.66601H9.16797V14.666ZM10.0013 7.99935C10.2374 7.99935 10.4355 7.91935 10.5955 7.75935C10.7555 7.59935 10.8352 7.40157 10.8346 7.16601C10.8346 6.9299 10.7546 6.73213 10.5946 6.57268C10.4346 6.41324 10.2369 6.33324 10.0013 6.33268C9.76519 6.33268 9.56741 6.41268 9.40797 6.57268C9.24852 6.73268 9.16852 6.93046 9.16797 7.16601C9.16797 7.40213 9.24797 7.60018 9.40797 7.76018C9.56797 7.92018 9.76575 7.9999 10.0013 7.99935ZM10.0013 18.8327C8.84852 18.8327 7.76519 18.6138 6.7513 18.176C5.73741 17.7382 4.85547 17.1446 4.10547 16.3952C3.35547 15.6452 2.76186 14.7632 2.32464 13.7493C1.88741 12.7355 1.66852 11.6521 1.66797 10.4993C1.66797 9.34657 1.88686 8.26324 2.32464 7.24935C2.76241 6.23546 3.35602 5.35352 4.10547 4.60352C4.85547 3.85352 5.73741 3.2599 6.7513 2.82268C7.76519 2.38546 8.84852 2.16657 10.0013 2.16602C11.1541 2.16602 12.2374 2.3849 13.2513 2.82268C14.2652 3.26046 15.1471 3.85407 15.8971 4.60352C16.6471 5.35352 17.241 6.23546 17.6788 7.24935C18.1166 8.26324 18.3352 9.34657 18.3346 10.4993C18.3346 11.6521 18.1157 12.7355 17.678 13.7493C17.2402 14.7632 16.6466 15.6452 15.8971 16.3952C15.1471 17.1452 14.2652 17.7391 13.2513 18.1768C12.2374 18.6146 11.1541 18.8332 10.0013 18.8327Z" fill="#797979" />
            </svg>
          }
        </h4>
        <h4 className='md:text-[40px] text-[30px] font-[700] text-[#1C1C1C] line-height-100'><span className='md:text-[18px] text-[14px]'>{hideCurrency ? '' : '$'}</span>{Price}<span className='md:text-[18px] text-[14px]'>{hideCurrency ? '%' : ''}</span></h4>
      </div>
      {PackIcon &&
        <img className='md:w-[90px] w-[50px]' src={PackIcon} alt="Package Icon" />
      }
    </div>
  )
}

export default QuotePackBox

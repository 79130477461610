import React from 'react'

const ReservRevenuBox = ({Count, BoxTag, Symbol, Ratio}) => {
  return (
    <div className="flex justify-between items-center bg-[#F3F3F3] rounded-[10px] p-[10px]">
        <h3 className='md:text-[40px] text-[24px] font-[800] text-[#1C1C1C]'>{Symbol?<span className='text-[20px]'>$</span> : ''}{Count}</h3>
        <span className='md:text-[16px] text-[12px] font-[400] text-[white] bg-[#F38030] rounded-[10px] py-[8px] px-[10px]'>{BoxTag}</span>
    </div>
  )
}

export default ReservRevenuBox

import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { login } from "../service/AuthApi";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { GetProfileName } from "../service/AdminApi";
import { GlobalContext } from "../globalContext";

import Logo from "../assets/images/logo.png";

const AdminLoginPage = () => {
  const { dispatch } = React.useContext(AuthContext);
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  const [showInvalid, setShowInvalid] = useState(false)

  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required(),
    })
    .required();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // React.useEffect(() => {
  //   getData();
  // }, []);
  // const getData = async () => {
  //   const t_airports = await getAirports();
  //   setAirpost(t_airports);
  // };

  const onLoginSuccess = (isSuccess, data) => {
    if (isSuccess) {
      const { token, role } = data;
    
      dispatch({
        type: "LOGIN",
        payload: data,
      });
      getData(token);
    } else {
      setShowInvalid(true)
setTimeout(() => {
  setShowInvalid(false)
}, 1500);
    
    }
  };

  const onSubmit = async (data) => {
    login(data, onLoginSuccess);
  };

  const [profileDetails, setProfileDetails] = useState([]);

  const getData = async (token) => {
    const profileData = await GetProfileName(token);
    setProfileDetails(profileData);
    dispatch({
      type: "USERDATA",
      payload: {
        user: profileData,
      },
    });
    navigate("/dashboard");
  };

  // React.useEffect(() => {
  //   getData();
  // }, []);

  return (
    <>
      <div className="flex flex-col gap-4 bg-slate-50	min-h-screen justify-center">
        <form
          className="access-card bg-white max-w-[400px] w-full mx-auto shadow-md p-8 rounded-2xl"
          onSubmit={handleSubmit(onSubmit)}
        >
          <NavLink to="/">
            <img
              className="w-full max-w-[140px] block mb-4 mx-auto object-contain"
              src={Logo}
              alt=""
            />
          </NavLink>
          <h5 className="text-[30px] font-[900] uppercase text-center mb-4">
            Login
          </h5>

          <fieldset className="flex gap-1 flex-col mt-4 rounded-[10px] bg-[#f0f0f0] p-[10px] border-none">
            <label className="block font-medium">Email</label>
            <input
              {...register("email")}
              className="rounded-[0px] bg-transparent outline-none shadow-transparent p-[0] border-none"
              type="email"
              placeholder="Enter Email Address"
            />
            <p className="text-red-500 text-xs italic">
              {errors.email?.message}
            </p>
          </fieldset>
          <fieldset className="flex gap-1 flex-col mt-2 rounded-[10px] bg-[#f0f0f0] p-[10px] border-none">
            <label className="block font-medium">Password</label>
            <input
              {...register("password")}
              className="rounded-[0px] bg-transparent outline-none shadow-transparent p-[0] border-none"
              type="password"
              placeholder="Enter Password"
            />
            <p className="text-red-500 text-xs italic">
              {errors.password?.message}
            </p>
          </fieldset>
          {/* <NavLink
            className="mt-2 text-[#626262] text-[16px] font-[500] mb-10 text-right block"
            to="/admin/forgot"
          >
            Forgot Password?
          </NavLink> */}
          <p className="text-red-500 text-[14px]" >{showInvalid?'Logins are Invalid': ''}</p>
          <button className="book-area-btn w-full mt-4" type="submit">
            Login
          </button>
        </form>
      </div>
    </>
  );
};

export default AdminLoginPage;

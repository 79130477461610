import React from 'react'
import { useState } from 'react';
import PageTitle from '../admin-components/PageTitle'
import { useEffect } from 'react';
import { GlobalContext, showToast } from "../globalContext";
import { AddEmailNotes } from '../service/AdminApi';
import { getEmailNote } from '../service/AdminApi';

import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../admin-components/QuillToolbar";
import 'react-quill/dist/quill.snow.css';

const EmailNotes = () => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [emailNote, setEmailNote] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const getData = async () => {
        const EmailNote = await getEmailNote();
        const emailContent = JSON.parse(EmailNote.note).emailNote;
        setTimeout(() => {
            setEmailNote(emailContent);
        }, 200);
    };

    React.useEffect(() => {
        getData()
    }, []);

    React.useEffect(() => {
        globalDispatch({
            type: "SETPATH",
            payload: {
                path: "email_note",
            },
        });

        globalDispatch({
            type: "SETPAGENAME",
            payload: {
                pageName: "Email Note",
            },
        });

        globalDispatch({
            type: "MENUBAR",
            payload: {
                menuBar: false,
            },
        });
    }, []);

    const AddNoteButton = () => {
        setIsLoading(true)
        AddEmailNotes(emailNote, (success, resp) => {
            if (success) {
                showToast(globalDispatch, "Email note added");
                setIsLoading(false)
            } else {
                showToast(globalDispatch, "Email note not added");
                setIsLoading(false)
            }
        });
    }

    return (
        <>
            <PageTitle />

            <div className="bg-white mt-[10px]">
                <EditorToolbar />
                <ReactQuill
                    id="description"
                    value={emailNote}
                    onChange={setEmailNote}
                    modules={modules}
                    formats={formats}
                />
            </div>
            <button
                onClick={AddNoteButton}
                type='button'
                className='py-1 px-5 rounded-[10px] bg-[#F38030] text-[18px] mt-4 text-[white] text-right block ml-auto w-fit'
            >
                {isLoading ? 'Updating..' : 'Save'}
            </button>

        </>
    )
}

export default EmailNotes;
